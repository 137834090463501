/* @flow
 *
 * Adds an `is-ready` class to the <html> tag when dom is ready.
 */

import $ from 'jquery';

$(() => {
  $('html').addClass('is-ready');
});
