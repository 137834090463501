/* @flow
 * Behavior which checks for 'android-app-banner'.
 */

import $ from 'jquery';
import * as storage from '../../utils/storage';

const LOCAL_STORAGE_KEY = 'hideAndroidAppBanner';

function persistVisibility(): void {
  storage.set(LOCAL_STORAGE_KEY, 'true');
}

function isHidden(): ?string {
  return storage.get(LOCAL_STORAGE_KEY);
}

function isAndroid(): boolean {
  return navigator.userAgent.match(/Android/i) !== null;
}

$(() => {
  if (!isAndroid() || isHidden()) return;
  const $el = $('[data-behavior=android-app-banner]');
  $el.removeClass('is-hidden');

  $el.find('[data-type=btn]').click(() => {
    $el.addClass('is-hidden');
    persistVisibility();
  });
});
