/* @flow
  Behavior to set the amount of a progress bar depending on the mid and duration
  data attributes.
  Needs data-mid and data-duration as attributes.
*/

import registerBehavior from '../../utils/registerBehavior';
import { updateProgBar } from '../../utils/progressBar';

registerBehavior({
  name: 'video-progress',
  init: (el) => {
    updateProgBar(el);
  },
});
