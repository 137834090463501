// @flow
import { EXPIRE_TIME } from 'components/WatchAlarm/index';

if (window.location.pathname !== '/wekker') {
  const timer = sessionStorage.getItem('alarmDuration');
  if (timer) {
    const setTime = Number(timer);
    const timeDiff = setTime - Date.now();
    // if the time difference is not bigger than 8 hours
    if (timeDiff > -EXPIRE_TIME) {
      setTimeout(() => {
        window.location.replace(`${window.location.origin}/wekker`);
      }, Math.max(0, timeDiff));
    }
  }
}
