/* @flow
 *
 * Component that renders a list of cards on the home with content for
 * children. It can be filtered and loads new content on the press of a button
 * or scroll.
 */

import React from 'react';
import { debounce } from 'lodash';
import moment from 'moment';
import 'moment/locale/nl';
import PaginatingCollection from '../CardCollection/PaginatingCollection';

type Props = {
  initialItems: Array<string>,
  initialPagePath: string,
};

type State = {
  items: Array<Object>,
  pagePath: string,
  isLoading: boolean,
  scrollPosition: number,
  isFirstPage: boolean,
  showTopButton: boolean,
}

export default class MissedBroadcasts extends React.PureComponent<Props, State> {
  actions: Object;

  constructor(props: Props): void {
    moment.locale('nl');
    super(props);
    this.state = {
      items: this.props.initialItems.map(item => JSON.parse(item)),
      previousSize: 0,
      pagePath: this.props.initialPagePath,
      isLoading: false,
      scrollPosition: window.scrollY,
      isFirstPage: true,
      showTopButton: false,
    };
  }

  componentDidMount(): void {
    window.addEventListener('scroll', debounce(() => {
      if (!this.state.showTopButton && window.scrollY > screen.height * 1.5) {
        this.setState({ showTopButton: true });
      } else if (this.state.showTopButton && window.scrollY < screen.height * 1.5) {
        this.setState({ showTopButton: false });
      }
    }, 200));
  }

  /* Checks to see if new items should be loaded.
   */
  startLoading = (): void => {
    if (this.state.isFirstPage) {
      return;
    }
    this.actions.startLoading();
  };

  loadNextPage = (): void => {
    if (this.state.pagePath === null || this.state.isLoading) {
      return;
    }
    this.setState(prevState => ({
      ...prevState,
      isLoading: true,
      isFirstPage: false,
    }));
    $.getJSON(this.state.pagePath,
      (res) => {
        this.setState(prevState => ({
          ...prevState,
          items: prevState.items.concat(res.broadcasts),
          pagePath: res.links.next,
          isFirstPage: false,
          isLoading: false,
          showTopButton: true,
        }));
      },
    );
  }

  formatDate = (date: Date): string => moment(date).format('DD MMMM YYYY [om] HH:MM');

  moveToTop = () => {
    window.scrollTo(0, 0);
    this.setState({ showTopButton: false });
  }

  episodeUrl = (item: Object): string => `/kinderfilmpjes/programmas/${item.serie.slug}/gemist/${item.mid}`

  renderTopButton = () =>
    <div className="btn__top" onClick={this.moveToTop} />

  pomsImage = (id: string):string => `https://images.poms.omroep.nl/image/s400x400/${id}.jpg`

  render(): React$Element<any> {
    return (
      <PaginatingCollection startLoading={() => !this.state.isFirstPage && this.loadNextPage()} className="l-row u-margin-top">
        {this.state.items.map(item => (
          <div key={item.id} className="l-col-4-l l-col-6-s">
            <a className="card card--inline" href={this.episodeUrl(item)} >
              <div className="card__image ratio ratio--16x9">
                <img alt={item.title} src={this.pomsImage(item.image.id)} className="item__image" />
              </div>
              <div className="card__overlay">
                {item.title}
                <br />
                {this.formatDate(item.start)}
              </div>
            </a>
          </div>
        ))}
        {this.state.isLoading && <div className="spinner spinner--tabbar" />}
        {this.state.isFirstPage && (
          <button
            className="btn load-more-button"
            onClick={this.loadNextPage}
          >
            <img src="/assets/icons/plus-green.svg" className="plus-icon" alt="plus" />
          </button>
        )}
        {this.state.showTopButton && !this.state.isFirstPage && this.renderTopButton()}
      </PaginatingCollection>
    );
  }
}
