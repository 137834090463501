import { Controller } from 'stimulus';

// Controller to control bulk selection. The approving of that and counting of selected items.
export default class extends Controller {
  static targets = ['bulkToggle', 'singleToggle', 'count'];

  connect() {
    this.checkToggle();
    this.updateCheckedCount();
  }

  toggleApproved() {
    this.singleToggleTargets.forEach(target => {
      if (target.checked) {
        target.parentNode.parentNode.classList.add("is-approved")
      } else {
        target.parentNode.parentNode.classList.remove("is-approved");
      }
    })
  }
  updateCheckedCount() {
    let checkedCount = this.singleToggleTargets.filter(item => !!item.checked).length
    this.countTarget.innerText = checkedCount;
  }

  toggleAll() {
    const toggle = this.bulkToggleTarget;
    const ids = this.singleToggleTargets;
    ids.forEach(id => id.checked = toggle.checked);
    this.updateCheckedCount();
    this.toggleApproved();
  }

  toggleSingle() {
    this.checkToggle();
    this.updateCheckedCount();
    this.toggleApproved();
  }

  checkToggle() {
    const ids = this.singleToggleTargets;
    const toggle = this.bulkToggleTarget;
    toggle.checked = ids.filter(id => !id.checked).length === 0;
  }

}
