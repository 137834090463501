// @flow

function updateProgBar(element: any): void {
  const bar = element.jquery ? element[0] : element;
  if (bar) {
    const mid = bar.getAttribute('data-mid');
    const progressObject = localStorage.getItem('zappelin_progress');
    let currentProgress = null;
    if (progressObject) {
      currentProgress = JSON.parse(progressObject).find(item => item.mid === mid);
    }
    if (currentProgress) {
      bar.style.width = `${currentProgress.ratio}%`;
    } else {
      bar.style = {};
    }
  }
}

function setProgBarStyle(mid: string): Object {
  const progressObject = localStorage.getItem('zappelin_progress');
  let currentProgress = null;
  if (progressObject) {
    currentProgress = JSON.parse(progressObject).find(item => item.mid === mid);
  }
  if (currentProgress) {
    return { width: `${currentProgress.ratio}%` };
  }
  return {};
}

export { updateProgBar, setProgBarStyle };
