/* @flow
 *
 * Checks for featured-link behavior. It toggles the is-featured-link-active
 * class depending on whether the featured-link has been visited. */

import $ from 'jquery';
import * as storage from '../../utils/storage';

const LOCAL_STORAGE_KEY = 'visitedFeaturedLinks';

type State = {
  els: JQuery,
  visitedId: string,
  featuredId: string,
  featuredPath: string,
  browserPath: string,
};

function getVisitedId(): string {
  return storage.get(LOCAL_STORAGE_KEY) || '';
}

function setVisitedId(path: string): void {
  storage.set(LOCAL_STORAGE_KEY, path);
}

// eslint-disable-next-line import/prefer-default-export
export function updateVisitedId(state: State): string {
  if (state.featuredPath === state.browserPath) {
    return state.featuredId;
  }

  return state.visitedId;
}

function getState(): ?State {
  const els = $('[data-behavior=featured-link]');

  if (els.length === 0) return;

  return { // eslint-disable-line consistent-return
    els,
    visitedId: getVisitedId(),
    featuredId: els.first().attr('data-id'),
    featuredPath: els.first().attr('data-path'),
    browserPath: window.location.pathname,
  };
}

$(() => {
  const state = getState();
  if (!state || !state.featuredPath) return;

  const newVisitedId = updateVisitedId(state);

  setVisitedId(newVisitedId);
  state.els.toggleClass('is-featured-link-active', newVisitedId !== state.featuredId);
});
