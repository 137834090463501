/* @flow
 *
 * Exports a single function to play a sound. Makes sure the browser supports Audio
 * and that only one sound is audible.
 */

let currentAudio = null;

function supportsWebAudio(): boolean {
  return 'Audio' in window;
}

export default (src: string): void => {
  if (!supportsWebAudio()) {
    return;
  }

  if (currentAudio) {
    currentAudio.volume = 0;
  }

  currentAudio = new Audio(src);
  currentAudio.play();
};
