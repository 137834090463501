/* @flow
 *
 * Component that renders a list of cards on the subsite home with content for
 * children. It can be filtered and loads new content on scroll.
 */

import React from 'react';
import ChildCardCollection from '../CardCollection/ChildCardCollection';
import PaginatingCollection from '../CardCollection/PaginatingCollection';
import HomeActions from '../../actions/home';

type Props = {
  initialItems: Array<Object>,
  initialPagePath: string,
}

type State = {
  items: Array<Object>,
  previousSize: number,
  pagePath: string,
  isLoading: boolean,
  scrollPosition: number,
  completed: boolean,
}

export default class CharacterHome extends React.PureComponent<Props, State> {
  actions: Object

  constructor(props: Props): void {
    super(props);
    this.state = {
      items: props.initialItems,
      previousSize: 0,
      pagePath: props.initialPagePath,
      isLoading: false,
      scrollPosition: window.scrollY,
      completed: false,
      filterAudio: new Audio(),
    };
    this.actions = new HomeActions(this);
  }

  componentDidMount(): void {
    this.actions.startLoading();
  }

  render(): React$Element<any> {
    return (
      <div>
        <PaginatingCollection
          startLoading={this.actions.startLoading}
        >
          <ChildCardCollection
            items={this.state.items}
            previousSize={this.state.previousSize}
          />
          {this.state.isLoading && <div className="spinner spinner--tabbar" />}
        </PaginatingCollection>
      </div>
    );
  }
}
