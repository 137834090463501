/* @flow
 *
 * Adds click handler to elementes with toggle behavior. When clicked it
 * searches for other elements via [data-toggle=NAME] where name is defined
 * on the toggle behavior element via data-target=NAME.
 */

import $ from 'jquery';

$(() => {
  $('[data-behavior=toggle]').on('click', (event) => {
    const target = $(event.currentTarget).attr('data-target');
    const toggleClass = $(event.currentTarget).attr('data-class') || 'is-hidden';
    $(`[data-toggle=${target}]`).toggleClass(toggleClass);
  });
});
