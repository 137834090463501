/* @flow
 *
 * Allows the parent menu to be opened and closed on the homepage.
 */

import $ from 'jquery';

$(() => {
  const control = $('[data-control=search-bar]');
  const menu = $('[data-toggle=search-bar]');
  const behavior = $('[data-behavior=search-bar]');
  const openMenu = (): void => {
    menu.toggleClass('is-hidden');
    behavior.toggleClass('parent-menu--visible');
  };
  control.on('click', () => {
    openMenu();
    return false;
  });
});
