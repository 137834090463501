/* @flow
 *
 * Endless scroll behavior. Based on the pagination behavior in Zapp, but
 * without the button.
 */

import $ from 'jquery';
import throttle from 'lodash/throttle';
import registerBehavior from '../../utils/registerBehavior';

const THRESHOLD = 800;
let isPaginating = false;
let page = 2;

type State = {
  containerEl: any,
  path: string,
}

function loadedItems(containerEl: any): number {
  return containerEl.children().length;
}

function addOffsetToPath(path: string): string {
  return `${path + (path.split('?')[1] ? '&' : '?')}page=${page}`;
}

function loadItems(state: State): void {
  isPaginating = true;
  const path = addOffsetToPath(state.path, loadedItems(state.containerEl));
  $.ajax({
    url: path,
    dataType: 'html',
    headers: { 'X-Pagination': 'true' },
    success: (html) => {
      page += 1;
      state.containerEl.append(html);
      isPaginating = false;
    },
  });
}

function getState(el: any): State {
  return {
    containerEl: el.find('[data-type=container]'),
    path: el.data('path'),
  };
}

function loadItemsIfNeeded(state: State): void {
  if ((!isPaginating && $(window).scrollTop() >
      $(document).height() - $(window).height() - THRESHOLD)) {
    loadItems(state);
  }
}

function initPagination(el: any): void {
  const state = getState(el);
  $(window).scroll(throttle(() => loadItemsIfNeeded(state), 500));
}

registerBehavior({
  name: 'endless-scroll',
  init: initPagination,
});
