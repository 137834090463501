// @flow

import { Controller } from 'stimulus';
import moment from 'moment';
const TOLERANCE_DAYS = 10;

const ERRORS_REQUIRE = {
  text: 'Dit veld moet ingevuld worden.',
  file: 'Je moet een foto kiezen.',
  hidden: 'Je moet een foto kiezen.',
  email: 'Vul een geldig e-mailadres in.',
  date: 'Vul de volledige geboortedatum in.',
  checkbox: 'Je moet akkoord gaan met de voorwaarden.',
};

export default class extends Controller {
  static targets = ['policy', 'form', 'field', 'dateField'];

  linkClicked(evt: Event): void {
    evt.preventDefault();
    // scroll with text at the top
    this.policyTarget.scrollIntoView(true);
    this.policyTarget.classList.add('birthdays__highlight');
    setTimeout(() => { this.policyTarget.classList.remove('birthdays__highlight'); }, 1500);
  }

  submitForm(evt: Event): void {
    if (!this.validateAllFields(this.fieldTargets)) {
      evt.preventDefault();
      this.formGroup(this.formTarget.querySelector('.birthdays__error')).scrollIntoView(true);
    }
  }

  onChangeField(evt: Event): void {
    this.validateField(evt.target);
  }

  onChangeDate(evt: Event): void {
    this.validateDate(this.dateFieldTarget, false);
  }

  onBlurDate(evt: Event): void {
    if (evt.relatedTarget && evt.relatedTarget.id.includes("birthday_child_birthday")) { return }
    this.validateDate(this.dateFieldTarget, true);
  }

  formGroup(field: HTMLElement): HTMLElement {
    return field.closest('.form-group');
  }

  errorContainer(field: HTMLElement): HTMLElement {
    return this.formGroup(field).querySelector('.birthdays__error-container');
  }

  clearError(field: HTMLElement): void {
    this.formGroup(field).classList.remove('has-errors');
    const errorContainer = this.errorContainer(field);
    const error = errorContainer.querySelector('.birthdays__error');
    if (error && errorContainer) errorContainer.removeChild(error);
  }

  validateField(field: HTMLInputElement): boolean {
    this.clearError(field);
    if (this.isEmpty(field)) {
      this.addError(field, ERRORS_REQUIRE[field.type]);
      return false;
    }
    if (this.isNoEmail(field)) {
      this.addError(field, 'E-mailadres klopt niet.');
      return false;
    }
    return true;
  }

  validateDate(field: HTMLElement, checkComplete: boolean): boolean {
    this.clearError(field);
    if (checkComplete && this.isDateIncomplete(field)) {
      this.addDateError(field, ERRORS_REQUIRE.date);
      return false;
    }
    if (this.isLate(field)) {
      this.addDateError(this.dateFieldTarget, `De aanmelding moet minimaal ${TOLERANCE_DAYS} dagen voor de verjaardag bij ons binnen zijn.`);
      return false;
    }
    return true;
  }

  validateAllFields(fields: Array<HTMLInputElement>): boolean {
    const dateValid = this.validateDate(this.dateFieldTarget, true);
    return fields.map(field => this.validateField(field))
      .every(i => i === true) && dateValid;
  }

  addError(field: HTMLInputElement, errorMsg: string): void {
    this.formGroup(field).classList.add('has-errors');
    const error = document.createElement('span');
    error.classList.add('birthdays__error');
    error.innerText = errorMsg;
    this.errorContainer(field).appendChild(error);
  }

  addDateError(field: HTMLElement, errorMsg: string): void {
    this.formGroup(field).classList.add('has-errors');
    const error = document.createElement('span');
    error.classList.add('birthdays__error');
    error.innerText = errorMsg;
    this.errorContainer(field).appendChild(error);
  }

  isNoEmail(field: HTMLInputElement): boolean {
    if (field === this.dateFieldTarget) { return false; }
    return field.validity.typeMismatch;
  }

  isEmpty(field: HTMLInputElement): boolean {
    if (field === this.dateFieldTarget) { return false; }
    if (field.type === 'hidden') {
      return field.value === '';
    }
    return field.validity.valueMissing;
  }

  isLate(field: HTMLElement): boolean {
    if (!field === this.dateFieldTarget) return false;
    const day = field.querySelector('#birthday_child_birthday_3i').selectedOptions[0].value.padStart(2, '0');
    const month = field.querySelector('#birthday_child_birthday_2i').selectedOptions[0].value.padStart(2, '0');
    const year = field.querySelector('#birthday_child_birthday_1i').selectedOptions[0].value;
    if (day === "" || month === "" || year === "") { return false }

    const birthday = moment(`${year}-${month}-${day}`).hours(0);
    const formDate = moment().startOf('day').year(birthday.year());
    const deadlineDate = formDate.clone().add(TOLERANCE_DAYS - 1, 'days');
    // true if between including today and 8 days from now
    return (birthday >= formDate) && (birthday <= deadlineDate);
  }

  isDateIncomplete(field: HTMLElement): boolean {
    if (!field === this.dateFieldTarget) return false;
    const day = field.querySelector('#birthday_child_birthday_3i').selectedOptions[0].value;
    const month = field.querySelector('#birthday_child_birthday_2i').selectedOptions[0].value;
    const year = field.querySelector('#birthday_child_birthday_1i').selectedOptions[0].value;
    if (day === "" || month === "" || year === "") {
      return true
    }

    return false
  }
}
