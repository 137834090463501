/* @flow
 *
 * Plays the audio file defined in data-src when you hover over the element.
 */

import $ from 'jquery';
import throttle from 'lodash/throttle';
import playSound from '../../utils/playSound';

const THROTTLE_WAIT = 500; // ms
const THROTTLE_OPTIONS = {
  trailing: false,
};

function onMouseEnter(event: JQueryEventObject): void {
  playSound($(event.delegateTarget).attr('data-src'));
}

$(() => {
  $('[data-behavior=audio-on-hover]').each((_i, el) => {
    $(el).on('mouseenter', throttle(onMouseEnter, THROTTLE_WAIT, THROTTLE_OPTIONS));
  });
});
