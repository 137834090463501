/* @flow
 *
 * Replace the content of the specified selector with the result of an xhr.
 */

import $ from 'jquery';
import registerBehavior from '../../utils/registerBehavior';

registerBehavior({
  name: 'replace-content',
  init: (el) => {
    el.on('click', (e) => {
      e.preventDefault();
      $.ajax({
        url: el.attr('href'),
        success: (content) => {
          $(el.data('selector')).html(content);
        },
      });
    });
  },
});
