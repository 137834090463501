// @flow

import React from 'react';

export default ({ page, goToNextPage, goToPreviousPage, title, width }) => {
  const pageClass = `book__page ${page.flipped ? 'flipped' : ''}`;
  const first = page.flipped ? page.zIndex - 1 : page.zIndex;
  const second = page.flipped ? page.zIndex : page.zIndex - 1;

  return (
    <div className={pageClass} style={{ zIndex: page.zIndex, width }}>
      <img src={page.front} className="book-page__front" alt={title} style={{ width, zIndex: first, transition: 'z-index 0s 0.3s' }} onClick={goToNextPage} />
      <img src={page.back} className="book-page__back" alt={title} style={{ width, zIndex: second, transition: 'z-index 0s 0.3s' }} onClick={goToPreviousPage} />
    </div>
  );
};
