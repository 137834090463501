// @flow
import React from 'react';
import type { Props } from './types';
import Player from './Player';

type State = {
  currentMid: string
}

// Component that wraps a player and allows it to play a list of mids.
class PlaylistPlayer extends React.Component {
  state: State;

  constructor(props: Props): void {
    super(props);
    this.state = {
      currentMid: props.playlist[0],
    };
  }

  nextVideo = () => {
    this.setState((prevState: State) => {
      const { playlist } = this.props;
      const oldIndex = playlist.indexOf(prevState.currentMid);
      const index = oldIndex >= playlist.length ? 0 : oldIndex + 1;
      return { currentMid: this.props.playlist[index] };
    });
  }

  playerProps = () => ({ ...this.props, mid: this.state.currentMid, onComplete: this.nextVideo })

  render(): React$Element<any> {
    return (
      <Player {...this.playerProps()} />
    );
  }
}

export default PlaylistPlayer;
