/* @flow
*
* Wrapper component that handles endless scroll.
*/
import React from 'react';
import throttle from 'lodash/throttle';

type Props = {
  children: Array<any>,
  startLoading: Function,
  className?: string,
}

type State = {
  scrollPosition: number,
}

export default class PaginatingCollection extends React.Component<Props, State> {
  constructor(props: Props): void {
    super(props);
    this.state = {
      scrollPosition: window.scrollY - 1,
    };
  }

  componentDidMount(): void {
    // TODO: Make sure the window is always completely filled with childcards
    window.addEventListener('load', this.paginate.bind(this));
    window.addEventListener('scroll', throttle(this.paginate.bind(this), 500));
  }

  paginate = (): void => {
    const target = document.body && document.body.scrollHeight - window.innerHeight - 800;
    const currentScrollPosition = window.scrollY;
    if (currentScrollPosition > this.state.scrollPosition &&
        currentScrollPosition > target) {
      this.props.startLoading();
    }
    this.setState({ scrollPosition: currentScrollPosition });
  }

  render() : React$Element<'div'> {
    return (
      <div className={this.props.className || ''}>
        {this.props.children}
      </div>
    );
  }
}
