/* @flow
*
* Actions for the home and subsitehome. These allow us to extract the
* logic of updating the state, which is very similar for both.
*/
import $ from 'jquery';

function load(path: string, callback: Function): void {
  $.ajax({
    url: path,
    dataType: 'json',
    success: callback,
  });
}

export default class HomeActions {
  component: Object;

  constructor(component: any): void {
    this.component = component;
  }

  startLoading = (): void => {
    if (this.component.state.isLoading ||
        (this.component.state.filter !== '' &&
         this.component.state.completed[this.component.state.filter])) {
      return;
    }
    this.component.setState(
      previous => ({ ...previous, isLoading: true }),
      () => load(this.component.state.pagePath, this.didLoad),
    );
  }

  loadSecondPage = (): void => {
    if (this.component.state.isLoading) {
      return;
    }
    this.component.setState(
      previous => ({ ...previous, isLoading: true, isFirstPage: false }),
      () => load(this.component.state.pagePath, this.didLoad),
    );

    // TODO: Implement footer in React and fix this
    document.getElementById('footer').style.display = 'none';
  }

  didLoad = (response: Object): void => {
    this.component.setState(previous => ({ ...previous,
      isLoading: false,
      completed: response.completed,
      pagePath: response._links.next,
      items: [...previous.items, ...response.items],
      previousSize: previous.items.length,
    }));
  }
}
