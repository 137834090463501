// @flow

/*
 * Check if the browser supports localStorage and returns a boolean;
 */
function isSupported(): boolean {
  try {
    return 'localStorage' in window && window.localStorage !== null;
  } catch (e) {
    return false;
  }
}

/*
 * Reads the item from localStorage
 *
 * Returns the string if present and localStorage is supported.
 */
export function get(key: string): ?string {
  if (!isSupported) return null;

  const item = localStorage.getItem(key);
  if (item) return item;
  return null;
}

/*
 * Sets item to local storage
 *
 * Doesn't do anything when localStorage isn't supported.
 */
export function set(key: string, value: string): void {
  if (!isSupported) return;
  localStorage.setItem(key, value);
}
