// @flow

import React from 'react';

type Props = {
  alarmValue: string,
  formatMinuteText: Function,
  increaseMinutes: Function,
  decreaseMinutes: Function,
  sliderChange: Function,
  setStorage: Function,
  minWatchingTime: number,
  maxWatchingTime: number,
}

const NewAlarm = (props: Props): React$Element<any> => (
  <div className="jumbotron u-centered">
    <h1 className="heading font-5xl">Wekker</h1>
    <div className="u-margin-top">
      <h1 className="heading heading--s">
        Stel in hoeveel minuten de website gebruikt mag worden.
        <br />
        Als de tijd om is, vergrendelt de website.
        <div className="watch-alarm__middle-break" />
        <img src="assets/icons/watchTimeIcon.svg" alt="timeicon" />
        <div className="watch-alarm__low-break" />
        {`${props.alarmValue} ${props.formatMinuteText(props.alarmValue)}`}
      </h1>
      <br />
      <div className="watch-alarm__container u-margin-top">
        <div className="watch-alarm__slidercontainer">
          <button className="watch-alarm__minus-icon" onClick={props.decreaseMinutes} />
          <div className="watch-alarm__slider-offset">
            <input
              type="range"
              min={String(props.minWatchingTime)}
              max={String(props.maxWatchingTime)}
              value={props.alarmValue}
              step="5"
              onChange={props.sliderChange}
              className="watch-alarm__slider"
              id="alarmSlider"
            />
          </div>
          <button className="watch-alarm__plus-icon" onClick={props.increaseMinutes} />
        </div>
        <div className="watch-alarm__low-break" />
        <a href="/" onClick={props.setStorage} className="btn btn-primary watch-alarm__btn">
          Wekker starten
        </a>
        <div className="watch-alarm__bottom-break" />
      </div>
    </div>
  </div>
);

export default NewAlarm;
