// @flow
import React from 'react';

type Props = {
  timerRunning: boolean,
  alarmValue: string,
  formatMinuteText: Function,
  numberToText: Function,
  validateInputDigits: Function,
  unlockAlarm: Function,
}

const LockedAlarm = (props: Props): React$Element<any> => (
  <div className="jumbotron u-centered">
    <h1 className="heading font-5xl">{props.timerRunning ? 'Wekker stoppen' : 'De tijd is om!'}</h1>
    <div className="u-margin-top">
      <h1 className="heading heading--s">
        {props.timerRunning &&
          <p>
            {`De website kan nog ${props.alarmValue} ${props.formatMinuteText(props.alarmValue)} `}
            gebruikt worden voordat de wekker afgaat
            <br />
            Vul onderstaande code in om de wekker te stoppen.
          </p>}
        {!props.timerRunning &&
          <p>
            De wekker is afgegaan.
            <br />
            Vul onderstaande code in om de website te ontgrendelen.
          </p>}
        <div className="watch-alarm__middle-break" />
        <img src="assets/icons/watchTimeIcon.svg" alt="timeicon" />
        <div className="watch-alarm__low-break" />
      </h1>
      <h1 className="heading heading--s" id="watch-alarm__code-text">{props.numberToText()}</h1>
      <br />
      <input
        type="tel"
        id="unlockField"
        name="unlockField"
        required
        minLength="4"
        maxLength="4"
        className="watch-alarm__unlock-field"
        onInput={props.validateInputDigits}
        size="4"
        title="Vul de vier getallen van hierboven als cijfers in."
      />
      <div className="watch-alarm__low-break" />
      <a href="/" onClick={props.unlockAlarm} className="btn btn-primary watch-alarm__btn">
        {props.timerRunning && 'Wekker stoppen'}
        {!props.timerRunning && 'Ontgrendel'}
      </a>
      <div className="watch-alarm__bottom-break" />
    </div>
  </div>
);

export default LockedAlarm;
