// @flow
import $ from 'jquery';

const token = $('meta[name="csrf-token"]').attr('content');
let npoEventHandler = null;
const npoEvents = {};

export function fetchPlayer(mid: string, params: Object): Promise<Object> {
  const url = '/npo_player';
  const data = { ...params, mid };
  return fetch(url, {
    method: 'POST',
    headers: {
      'X-CSRF-Token': token,
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(data),
  })
    .then(res => res.json())
    .catch(error => new Error(error));
}

function handler(event: Object): void {
  try {
    const parsedEvent = JSON.parse(event.data);
    if (npoEvents[parsedEvent.elementId][parsedEvent.event]) {
      npoEvents[parsedEvent.elementId][parsedEvent.event](parsedEvent);
    }
  } catch (e) {
    if (
      !event.data === undefined &&
      !event.data.startsWith('ima') &&
      !event.data.startsWith('get')
    ) {
      throw new Error(e);
    }
  }
}

export function addHandler(playerId: string, eventName: string, callback: Function): void {
  if (npoEventHandler === null) {
    npoEventHandler = window.addEventListener('message', handler, false);
  }
  npoEvents[playerId] = { ...npoEvents[playerId], [eventName]: callback };
}

export function removeHandlers(playerId: string): void {
  Object.keys(npoEvents[playerId]).forEach((key) => {
    window.removeEventListener('message', npoEvents[playerId][key]);
  });
}

export function addPlayer(embedCode: string, id: string): void {
  const range = document.createRange();
  const container = document.getElementById(id);
  if (container) {
    range.setStart(container, 0);
    container.appendChild(range.createContextualFragment(embedCode));
  }
}

export function fetchPomsData(mid: string, cb: any): void {
  $.ajax({
    url: `https://e.omroep.nl/metadata/${mid}`,
    dataType: 'jsonp',
    success: cb,
  });
}
