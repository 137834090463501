/* @flow
 * Starts the fancybox on page load
 */

import $ from 'jquery';
import fancybox from 'fancybox';

fancybox($);

$(() => {
  $('.fancybox .fancy').fancybox();
});
