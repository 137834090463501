/* @flow
 *
 * Adds click handler to elementes with expanding behavior. When clicked it
 * searches for other elements via [data-expand=target] and expands them. It
 * hides the expand button when this is done.
 */

import $ from 'jquery';

$(() => {
  $('[data-behavior=expand]').on('click', '[data-expand=control]', (event) => {
    const target = $(event.delegateTarget).find('[data-expand=target]');
    target.toggleClass('is-expanded');
    $(event.target).hide();
  });
});
