/* @flow
 *
 * Renders a collection of ChildCards and animates them.
 */

import React from 'react';
import { TransitionGroup } from 'react-transition-group';
// import { dropRight } from 'lodash';
import ChildCard from './ChildCard';

// const MAX_ITEMS = 6;
// const roundItems = (items: Array<Object>) => {
//   if (items.length < MAX_ITEMS) {
//     return items;
//   }
//   return items.length % MAX_ITEMS === 0 ? items : dropRight(items, items.length % MAX_ITEMS);
// };

const ChildCardCollection = (props: any) => {
  const className = 'l-row content-list';
  return (
    <TransitionGroup className={className} appear={true}>
      { props.items.map((item, index) => (<ChildCard
        card={item}
        index={index - props.previousSize}
        key={index}
      />)) }
    </TransitionGroup>
  );
};

export default ChildCardCollection;
