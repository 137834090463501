/* @flow
 *
 * Toggles the correct classes on the search filter.
 */

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['list', 'caret']

  toggle(): void {
    this.listTarget.classList.toggle('hidden');
    this.caretTarget.classList.toggle('rotate-180');
  }
}
