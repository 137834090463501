/* @flow
 *
 * Component that renders a list of characters.
 */

import React from 'react';
import Swiper from 'swiper/bundle';
import playSound from '../../utils/playSound';

type Props = {
  characters: Array<any>,
  current: ?Number
}

export default class Caroussel extends React.PureComponent<Props> {
  constructor(props: Props): void {
    super(props);
    this.caroussel = React.createRef();
  }

  componentDidMount(): void {
    // eslint-disable-next-line no-new
    new Swiper(this.caroussel.current, {
      initialSlide: this.props.current || 0,
      centeredSlides: true,
      slidesPerView: 3,
      paginationClickable: true,
      loop: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        340: {
          slidesPerView: 3,
        },
        470: {
          slidesPerView: 5,
        },
        1300: {
          slidesPerView: 5,
        },
      },
    });
  }

  render(): React$Element<div> {
    const characters = this.props.characters;
    return (
      <>
        <div ref={this.caroussel} className="swiper-container">
          <div className="swiper-wrapper">
            {characters.map(character => (
              <a key={character.id} onMouseOver={() => playSound(character.audioUrl)} href={`/${character.slug}`} className="character-link swiper-slide">
                <img alt={character.name} src={character.imageUrl} className="character-link__icon" style={{ borderColor: character.backgroundColor }} />
                <p className="character-link__text">{character.name}</p>
              </a>
            ))}
          </div>
        </div>
        <div className="swiper-button-next character-button-next" />
        <div className="swiper-button-prev character-button-prev" />
      </>
    );
  }
}
