// @flow

import '../stylesheets/styles.scss';

// Polyfills
// require('core-js/fn/set');
// require('core-js/fn/string/repeat');
// require('core-js/fn/promise');
require('@babel/polyfill');
require('whatwg-fetch');
const npmJQuery = require('jquery');
const values = require('object.values');

// Rails UJS
import Rails from "@rails/ujs"
Rails.start()

if (!Object.values) {
  values.shim();
}

// Expose jQuery to window. The comscore tracker needs a global jQuery.
if (!window.jQuery || !window.$) {
  window.$ = npmJQuery;
  window.jQuery = npmJQuery;
}

// Application modules
require('initializers/index');
require('behavior/index');
require('components/index');

// Stimulus
import { Application } from 'stimulus';
import { definitionsFromContext } from 'stimulus/webpack-helpers';
import "@stimulus/polyfills";

const application = Application.start();
const context = require.context('controllers', true, /\.js$/);
application.load(definitionsFromContext(context));
