// @flow

import * as React from 'react';
import type { Props } from './types';

const animationDuration = 10;

type State = {
  endTimer: number,
};

export default class EndScreen extends React.Component<Props, State> {
  constructor(props: Props): void {
    super(props);
    this.state = {
      endTimer: 0,
    };
  }

  componentDidMount(): void {
    if (this.props.nextUrl) {
      window.setInterval(() => {
        this.setState(
          prev => ({ ...prev, endTimer: prev.endTimer + 1 }),
          () => {
            if (this.state.endTimer >= animationDuration) {
              window.location.href = this.props.nextUrl;
            }
          },
        );
      }, 1000);
    }
  }

  render(): any {
    return (
      <div className="player__end">
        {this.props.nextUrl && (
          <div className="player__next">
            <a href={this.props.nextUrl}>
              <div className="countdown-timer">
                <div className="countdown-timer__circle">
                  <svg>
                    <circle
                      r="24"
                      cx="26"
                      cy="26"
                      style={{
                        animation: `countdown-animation ${animationDuration}s linear`,
                      }}
                    />
                  </svg>
                </div>
                <div className="countdown-timer__text">
                  <div className="player__play-icon" />
                </div>
              </div>
              <p>Volgende aflevering</p>
            </a>
          </div>
        )}
      </div>
    );
  }
}
