// @flow

import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay as play, faPause as pause } from '@fortawesome/free-solid-svg-icons';
import Parser from 'react-html-parser';

import type { Podcast } from './types';

export const formatDuration = (duration: string) => {
  const [hours, minutes, seconds] = duration.split(':').map(element => parseInt(element));
  const hourString = hours > 0 ? `${hours} u ` : '';
  const minuteString = `${seconds > 30 ? minutes + 1 : minutes} min`;
  return `${hourString}${minuteString}`;
};

const formatDateShort = (dateString: string): string => {
  const date = new Date(dateString);
  return date.toLocaleDateString('nl', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });
};


const Episode = ({ activeFileUrl, playAudio, fileUrl, title, duration, publishedAt, description }) => {
  const audio = useRef(null);
  const [playing, setPlaying] = useState(false);
  const [showDescription, setShowDescription] = useState(false);

  useEffect(() => {
    if (activeFileUrl !== fileUrl) {
      audio.current && audio.current.pause();
    }
  }, [activeFileUrl]);

  useEffect(() => {
    if (audio.current) {
      audio.current.addEventListener('play', () => setPlaying(true));
      audio.current && audio.current.addEventListener('pause', () => setPlaying(false));
    }
  }, [audio]);

  return (
    <div className="padding-bottom-1">
      <div className="flex width-100">
        <div onClick={(e) => { playAudio(fileUrl, audio.current); }} className={`podcast__play ${playing ? 'active' : undefined}`}>
          {playing ? <FontAwesomeIcon icon={pause} /> : <FontAwesomeIcon icon={play} />}
        </div>
        <div className="flex-1">
          <p className="font-2xl font-bariol">{title}</p>
          <p>
            <span style={{ float: 'right' }}>
              <a href="#" className="font-sm color-gray-4" onClick={(e) => { e.preventDefault(); setShowDescription(!showDescription); }}>Details</a>
            </span>
            <span className="color-gray-4 font-sm">
              {formatDateShort(publishedAt)}
              {' '}
              &mdash;
              {formatDuration(duration)}
            </span>
          </p>
        </div>
      </div>
      <div className={showDescription ? undefined : 'u-hide'}>
        <p className="u-margin-top-1 font-rg color-gray-3">{description}</p>
      </div>
      <div className={activeFileUrl !== fileUrl ? 'u-hide' : 'u-margin-top-1'}><audio className="padding-top-1" style={{ width: '100%' }} ref={audio} controls src={fileUrl} preload="none" /></div>
    </div>
  );
};

export default ({ title, description, imageUrl, episodes, platformsText }: Podcast) => {
  const [activeFileUrl, setActiveFileUrl] = useState(null);

  const playAudio = (fileUrl, player) => {
    setActiveFileUrl(fileUrl);
    if (!player) {
      return;
    }
    if (player.paused) {
      player.play();
    } else {
      player.pause();
    }
  };

  return (
    <div className="l-content u-margin-top-2">
      <div className="box box--no-shadow">
        <div className="box__body md:flex">
          <div className="podcast__header u-padding-bottom">
            <div className="flex lg:block items-center">
              <div className="podcast__image"><img src={imageUrl} alt={title} width="100%" /></div>
              <p className="font-3xl font-bariol">{title}</p>
            </div>
            <p className="font-rg">{description}</p>
          </div>
          <div className="podcast__episodes">
            {episodes.map(episode => (
              <Episode key={episode.fileUrl} playAudio={playAudio} activeFileUrl={activeFileUrl} {...episode} />
            ))}
          </div>
        </div>
      </div>
      {!!platformsText &&
       <div className="box box--no-shadow">
         <div className="box__body md:flex font-rg">
           {Parser(platformsText)}
         </div>
       </div>
      }
    </div>
  );
};
