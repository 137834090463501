// @flow

import React, { Component } from 'react';
import Page from './Page';

class Reader extends Component {
  bookWrapper: any;

  constructor(props: Props): void {
    super(props);
    this.state = {
      pageIndex: 0,
      currentAudio: props.book.pages[0].audio,
      pages: this.pageList(props.book.pages),
      disabled: false,
      pageWidth: '200px',
    };
  }

  props: Props

  playAudio = (): void => {
    if (this.state.currentAudio) {
      const player = document.getElementById('player');
      player.load();
      player.play();
    }
  }

  goToNextPage = (): void => {
    if (this.state.disabled) { return; }
    this.setState(
      (prevState) => {
        const newIndex = prevState.pageIndex + 1;
        return {
          pageIndex: newIndex,
          currentAudio: this.props.book.pages[newIndex].audio,
          pages: prevState.pages.map((item, index) => {
            if (index === prevState.pageIndex) {
              return { ...item, flipped: true, zIndex: index + 100 };
            }
            return item;
          }),
          disabled: true,
        };
      }, () => {
        this.playAudio();
        window.setTimeout(this.normalizePages, 1000);
      });
  }

  goToPreviousPage = (): void => {
    if (this.state.disabled) { return; }
    this.setState(
      (prevState) => {
        const newIndex = prevState.pageIndex - 1;
        return {
          pageIndex: newIndex,
          currentAudio: this.props.book.pages[newIndex].audio,
          pages: prevState.pages.map((item, index) => {
            if (index === newIndex) {
              return { ...item, flipped: false, zIndex: 100 };
            }
            return item;
          }),
          disabled: true,
        };
      }, () => {
        this.playAudio();
        window.setTimeout(this.normalizePages, 1000);
      });
  }

  normalizePages = (): void => {
    this.setState(prevState => ({
      pages: prevState.pages.map((item, index) => {
        if (item.flipped) {
          return { ...item, zIndex: index };
        }
        return { ...item, zIndex: this.props.book.pages.length - index };
      }),
      disabled: false,
    }));
  }

  isLastPage(): boolean {
    return (this.state.pageIndex === this.props.book.pages.length - 1);
  }

  isFirstPage(): boolean {
    return ((this.state.pageIndex === 0));
  }

  /* Converts the pages from the server into something we can render on both
   * sides.
   */
  pageList = (initialPages: Array): Array =>
    initialPages.map((page, index, pages) => {
      if (index === pages.length - 1) { return null; }
      const frontImage = index === 0 ? page.image1 : page.image2;
      const backImage = pages[index + 1].image1;
      return {
        front: frontImage,
        back: backImage,
        zIndex: pages.length - index,
        flipped: false,
      };
    }).filter(item => item !== null);

  render(): React$Element<any> {
    const { book } = this.props;
    return (
      <div className="reader">
        {this.isFirstPage() ? null : <button className="book__btn book__btn-left" onClick={this.goToPreviousPage} />}
        <div
          className="book__wrapper"
          ref={(node) => { this.bookWrapper = node; }}
        >
          {this.state.pages.map((page, index) => (<Page
            page={page}
            key={index}
            title={book.title}
            width={this.state.pageWidth}
            goToNextPage={this.goToNextPage}
            goToPreviousPage={this.goToPreviousPage}
          />))}
        </div>
        {this.isLastPage() ? null : <button className="book__btn book__btn-right" onClick={this.goToNextPage} />}
        <audio id="player">
          <source src={this.state.currentAudio} type="audio/mpeg" />
        </audio>
      </div>
    );
  }

  componentDidMount(): void {
    window.addEventListener('resize', this.onResize, false);
    this.onResize();
    this.playAudio();
  }

  componentWillUnmount(): void {
    window.removeEventListener('resize', this.onResize);
  }

  onResize = () => {
    const wrapperWidth = this.bookWrapper.clientWidth;
    this.setState({ ...this.state, pageWidth: `${wrapperWidth / 2}px` });
  }
}

export default Reader;
