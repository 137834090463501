/* @flow
 *
 * Component that renders a list of cards on the home with content for
 * children. It can be filtered and loads new content on the press of a button
 * or scroll.
 */

import { debounce } from 'lodash';
import React from 'react';
import ChildCardCollection from '../CardCollection/ChildCardCollection';
import PaginatingCollection from '../CardCollection/PaginatingCollection';
import HomeActions from '../../actions/home';

type Props = {
  initialItems: Array<Object>,
  initialPagePath: string,
  filters: Array<string>,
  homepageFilter: string,
  loadMoreAudio: string,
};

type State = {
  items: Array<Object>,
  previousSize: number,
  pagePath: string,
  isLoading: boolean,
  scrollPosition: number,
  completed: boolean,
  isFirstPage: boolean,
  loadMoreAudioPlaying: boolean,
  loadMoreAudio: Object,
  showTopButton: boolean,
}

export default class Home extends React.PureComponent<Props, State> {
  actions: Object;

  constructor(props: Props): void {
    super(props);
    this.state = {
      items: this.props.initialItems,
      previousSize: 0,
      pagePath: this.props.initialPagePath,
      isLoading: false,
      completed: false,
      scrollPosition: window.scrollY,
      isFirstPage: true,
      loadMoreAudioPlaying: false,
      loadMoreAudio: new Audio(this.props.loadMoreAudio),
      showTopButton: false,
    };

    this.actions = new HomeActions(this);
  }

  componentDidMount(): void {
    window.addEventListener('scroll', debounce(() => {
      if (!this.state.showTopButton && window.scrollY > screen.height * 1.5) {
        this.setState({ showTopButton: true });
      } else if (this.state.showTopButton && window.scrollY < screen.height * 1.5) {
        this.setState({ showTopButton: false });
      }
    }, 200));
  }

  /* Checks if loadMoreAudio is playing, and plays it if not so.
    */
  startLoadMoreAudio(): void {
    if (!this.state.loadMoreAudioPlaying) {
      this.setState({ loadMoreAudioPlaying: true });
      this.state.loadMoreAudio.play();
    }
  }

  stopLoadMoreAudio(): void {
    if (this.state.loadMoreAudioPlaying) {
      this.setState({ loadMoreAudioPlaying: false });
      this.state.loadMoreAudio.pause();
      this.state.loadMoreAudio.currentTime = 0;
    }
  }

  /* Returns the filtered list of items based on the filter in the state.
   */
  filteredItems(): Array<Object> {
    const filter = this.props.homepageFilter;
    if (filter === 'index') {
      return this.state.items;
    }
    const filterItem = item => item.contentType === filter;
    return this.state.items.filter(filterItem);
  }

  /* Checks to see if new items should be loaded.
   */
  startLoading = (): void => {
    if (this.state.isFirstPage) {
      return;
    }
    this.actions.startLoading();
  };

  moveToTop = () => {
    window.scrollTo(0, 0);
    this.setState({ showTopButton: false });
  }

  renderTopButton = () => <div className="btn__top" onClick={this.moveToTop} />

  render(): React$Element<any> {
    return (
      <PaginatingCollection startLoading={this.startLoading}>
        <ChildCardCollection
          items={this.filteredItems()}
          previousSize={this.state.previousSize}
        />
        {this.state.isLoading && <div className="spinner spinner--tabbar" />}
        {this.state.isFirstPage && (
          <React.Fragment>
            <button
              className="btn load-more-button"
              onClick={this.actions.loadSecondPage}
              onMouseEnter={() => this.startLoadMoreAudio()}
            >
              <img src="/assets/icons/plus-green.svg" className="plus-icon" alt="plus" />
            </button>
          </React.Fragment>
        )}
        {this.state.showTopButton && !this.state.isFirstPage && this.renderTopButton()}
      </PaginatingCollection>
    );
  }
}
