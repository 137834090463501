/* @flow
 *
 * Divide content over one / two / three columns, depending on screen width.
 */

import $ from 'jquery';
import { range } from 'lodash';
import registerBehavior from '../../utils/registerBehavior';

const TWO_COLUMN_WIDTH = 800;
const THREE_COLUMN_WIDTH = 1200;

let numberOfColumns = 1;

function calculateNumberOfColumns(width: number): number {
  if (width < TWO_COLUMN_WIDTH) {
    return 1;
  }
  if (width < THREE_COLUMN_WIDTH) {
    return 2;
  }
  return 3;
}

function createColumns(number: number): any {
  return range(number).map(
    () => $(`<div class="column column--${numberOfColumns}"/>`),
  );
}

function findContents(el: any): any {
  return el.find('[data-index]')
    .sort((c1, c2) => ($(c1).data('index') > $(c2).data('index') ? 1 : -1));
}

function appendContentToColumn(content: any, columns: any): void {
  columns[content.data('index') % columns.length].append(content);
}

function divideContents(el: any): void {
  const newNumberOfColumns = calculateNumberOfColumns(el.width());
  if (newNumberOfColumns !== numberOfColumns) {
    numberOfColumns = newNumberOfColumns;
    const columns = createColumns(numberOfColumns);
    const contents = findContents(el);
    contents.each((i, content) => { appendContentToColumn($(content), columns); });
    el.html(columns);
  }
}

registerBehavior({
  name: 'responsive-columns',
  init: (el) => {
    $(window).resize(() => { divideContents(el); }).resize();
  },
});
