/* @flow
 *
 * Looks up an item with a data-id param and
 * focuses it when item with data-focus-on="<data-id name>" is clicked.
 */

import $ from 'jquery';

function focusOn(event: Event): void {
  const focusOnItem = $(event.currentTarget).attr('data-focus-on');
  window.setTimeout(() => {
    $(`[data-id="${focusOnItem}"]`).focus();
  }, 50);
}

$(() => {
  $('[data-focus-on]').on('click', event => focusOn(event));
});
