// @flow
import * as React from 'react';
import Cookies from 'js-cookie';
import type { Props } from './types';
import EndScreen from './EndScreen';

const MAX_PROGRESS_ITEMS = 500;
const PROGRESS_RESET_THRESH = 98;

type State = {
  modalVis: boolean;
  url: string;
  store: string;
  ended: boolean;
  storeImg: string;
}

export default class Player extends React.Component<Props, State> {
  elementId: string;
  node: HTMLElement;
  setupParams: Object;
  notFoundAudio: Audio;
  modalMsg: string;
  nodalLink: string;
  modalLinkAndroid: string;
  modalLinkIOS: string;
  totalDuration: number | null;
  IOSImage: string;
  AndroidImage: string;

  constructor(props: Props): void {
    super(props);
    this.state = {
      modalVis: false,
      url: '',
      store: '',
      ended: false,
      storeImg: '',
    };
    this.elementId = `player-id-${Math.floor(Math.random() * 1000 * 1000)}`;
    this.modalLinkAndroid = 'https://play.google.com/store/apps/details?id=nl.omroep.zappelin.android';
    this.modalLinkIOS = 'https://itunes.apple.com/nl/app/npo-zappelin/id428263410?mt=8';
    this.modalMsg = 'Jammer,  je kunt dit filmpje niet kijken. Kijk het filmpje in de Zappelin app.';
    this.IOSImage = '/assets/app-stores/app_store.png';
    this.AndroidImage = '/assets/app-stores/play_store.png';
    this.notFoundAudio = new Audio();
    if (this.notFoundAudio instanceof HTMLAudioElement) {
      this.notFoundAudio.src = props.notFoundAudio;
    }
    this.totalDuration = null;
    this.setupParams = {
      autoplay: props.autoplay,
      placeholder: props.placeholder,
      noAds: props.noAds,
      elementId: this.elementId,
      endscreen: props.endscreen,
      recommendations: props.recommendations,
      moreButton: props.moreButton,
      nextEpisode: props.nextEpisode,
      hasSettings: props.hasSettings,
      stylesheet: props.stylesheet,
      trackProgress: props.trackProgress,
    };
  }

  getProgress = (): number => {
    const allProgress = localStorage.getItem('zappelin_progress');
    if (allProgress) {
      const currentProgress = JSON.parse(allProgress).find(item => item.mid === this.props.mid);
      if (currentProgress) {
        return currentProgress.ratio >= PROGRESS_RESET_THRESH ? 0 : currentProgress.progress;
      }
    }
    return 0;
  }

  percToSec = (perc: number) : number => {
    if (this.totalDuration) {
      return (perc / 100) * this.totalDuration;
    }
    return 0.0;
  }

  secToPerc = (duration: number): number => {
    if (this.totalDuration) {
      return (100 / this.totalDuration) * duration;
    }
    return 0.0;
  }

  saveProgress = (progress: number): void => {
    if (!progress) return;
    const allProgress = localStorage.getItem('zappelin_progress');
    // Get progress from localStorage entry or create array
    const progressDb = allProgress ? JSON.parse(allProgress) : [];
    const progressObject = {
      mid: this.props.mid,
      progress: progress.toFixed(3),
      ratio: this.secToPerc(progress).toFixed(3),
    };
    const foundObject = progressDb.find(item => item.mid === this.props.mid);
    if (foundObject) { // entry for this mid is found
      // If the progress is within a rounded margin of the found progress, do nothing
      if (Math.round(parseFloat(foundObject.progress)) === Math.round(progress)) return;
      const index = progressDb.findIndex(item => item.mid === this.props.mid);
      // overwrite entry with new duration
      progressDb[index] = progressObject;
    } else {
      // no mid entry is found
      if (progressDb.length > MAX_PROGRESS_ITEMS - 1) {
        // remove the extra entries in the list
        progressDb.splice(0, progressDb.length - MAX_PROGRESS_ITEMS);
      }
      // Add a new duration entry at the end of the list
      progressDb.push(progressObject);
    }
    try {
      // save the list
      localStorage.setItem('zappelin_progress', JSON.stringify(progressDb));
    } catch (e) {
      throw e;
    }
  }

  handleProgress = (actualEvent: any): void => {
    if (actualEvent.time > 0) {
      this.saveProgress(actualEvent.time);
    }
  }

  handleDuration = (actualEvent: any): void => {
    if (actualEvent.to > 0) {
      this.totalDuration = actualEvent.to;
    }
  }

  handleDrm = (): void => {
    if (window.navigator.userAgent.indexOf('iPhone') > -1 || window.navigator.userAgent.indexOf('iPad') > -1) {
      this.setState({ modalVis: true, url: this.modalLinkIOS, store: 'App Store', storeImg: this.IOSImage });
    } else if (window.navigator.userAgent.indexOf('SamsungBrowser') > -1 || window.navigator.userAgent.indexOf('Chrome') > -1) {
      this.setState({ modalVis: true, url: this.modalLinkAndroid, store: 'Play Store', storeImg: this.AndroidImage });
    }
  }

  handleError = (): void => {
    if (window.navigator.userAgent.indexOf('iPhone') > -1 || window.navigator.userAgent.indexOf('iPad') > -1) {
      this.setState({ modalVis: true, url: this.modalLinkIOS, store: 'App Store', storeImg: this.IOSImage });
    } else if (/android/i.test(window.navigator.userAgent)) {
      this.setState({ modalVis: true, url: this.modalLinkAndroid, store: 'Play Store', storeImg: this.AndroidImage });
    }
  }

  recommendation = (actualEvent: any): void => {
    window.location.href = `/mid/${actualEvent.video.id}`;
  }

  setSiteId = (): void => {
    const userId = Cookies.getJSON('atuserid').val;
    const iframe = ((document.getElementById(`iframe-${this.props.mid}`) : any) : HTMLIFrameElement);
    iframe.contentWindow.postMessage(JSON.stringify({ event: 'NPO_SET_SMARTTAG_USER_ID', userId }), '*');
  }

  async componentDidMount(): void {
    const response = await fetch('/api/app/v5/player_tokens', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ payload: this.props.playerTokenPayload }),
    });

    if (!response.ok) {
      // eslint-disable-next-line
      console.error('Failed to fetch JWT');
      return;
    }

    const data = await response.json();
    const jwt = data.token;

    const container = document.getElementById(this.elementId);
    const options = {
      endpoint: `https://${this.props.subdomain}.npoplayer.nl`,
      startOffset: this.getProgress(),
    };

    const npotag = {
      brand: this.props.npotagBrand,
      brand_id: this.props.npotagBrandId,
      platform: this.props.npotagPlatform,
      platform_version: this.props.npotagPlatformVersion,
    };

    const playerConfig = {
      key: this.props.bitmovinKey,
      analytics: {
        key: this.props.bitmovinAnalyticsKey,
      },
      playback: {
        autoplay: this.setupParams.autoplay,
      },
    };

    // eslint-disable-next-line
    const npoPlayer = new NpoPlayer.default(container, playerConfig, npotag);

    npoPlayer.player.on('timechanged', this.handleProgress);
    npoPlayer.player.on('durationchanged', this.handleDuration);
    npoPlayer.player.on('ready', () => { this.totalDuration = npoPlayer.player.getDuration(); });
    npoPlayer.player.on('playbackfinished', () => {
      this.setState({ ended: true });
      if (this.props.onComplete) {
        this.props.onComplete();
      }
    });

    npoPlayer.loadStream(jwt, options);
  }

  onVideoNotFound = (): Promise<*> => (
    new Promise((resolve, reject) => {
      const waitForElement = () => {
        const player = document.querySelector(`#${this.elementId}`);
        const msg = document.querySelector('.npo-player-overlay-message');
        if (!!msg && !player) {
          resolve(msg);
        } else if (!msg && !!player) {
          reject(new Error("Video can't be shown."));
        } else {
          window.requestAnimationFrame(waitForElement);
        }
      };
      waitForElement();
    })
  )

  componentWillUnmount(): void {
    this.props.removeHandlers(this.elementId);
  }

  render(): any {
    return (
      <React.Fragment>
        {this.state.modalVis &&
          (<div className="player__store-modal" >
            <div className="player__inner-modal">
              <p>{ this.modalMsg }</p>
              <br />
              <div className="player__modal-url">
                <a href={this.state.url}>
                  <img src={this.state.storeImg} className="player__store-img" alt="storeImg" />
                </a>
              </div>
            </div>
          </div>)
        }
        {this.state.ended &&
          <EndScreen {...this.props} />
        }
        <div className="player__body" id={this.elementId} />
      </React.Fragment>
    );
  }
}
