/* @flow
 *
 * Automatically updates the content of an element. [data-interval=SECONDS] is
 * used to specify how often the element is updated. [data-path=PATH] provides
 * the path from which the updated content is repeatedly requested.
 */

import $ from 'jquery';

function updateContent(el: any, path: string, interval: number): void {
  $.ajax({
    url: path,
    success: (content) => {
      el.html(content);
    },
  }).always(() => {
    window.setTimeout(() => { updateContent(el, path, interval); }, interval);
  });
}

$(() => {
  $('[data-behavior=auto-update]').each((i, el) => {
    const $el = $(el);
    updateContent($el, $el.data('path'), $el.data('interval') * 1000);
  });
});
