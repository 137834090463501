import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['showAllButton', 'broadcast'];

  showAllBroadcasts(event) {
    event.preventDefault();
    this.broadcastTargets.forEach((broadcast) => broadcast.classList.remove("display-none"))
    this.showAllButtonTarget.classList.add("display-none")
  }
}
