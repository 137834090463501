/* @flow
 *
 * Adds an `is-touch` class to the <html> tag when the devise supports
 * touch events.
 */

import $ from 'jquery';

function isTouchDevice(): boolean {
  return 'ontouchstart' in window || Boolean(navigator.maxTouchPoints);
}

$(() => {
  if (isTouchDevice()) {
    $('html').addClass('is-touch');
  }
});
