/* @flow
 *
 * Simple component that wraps NPO Player and responds to React lifecycle
 * to update it. You set the width, mid and other options as props and you
 * can change these to refresh the NPO Player itself with the new params.
 */
import React from 'react';
import Player from './Player';
import type { Props } from './types';
import { fetchPlayer, addPlayer, addHandler, removeHandlers } from './utils';

// eslint-disable-next-line react/prefer-stateless-function
class PlayerContainer extends React.Component<Props> {
  static defaultProps = {
    fetch: fetchPlayer,
    render: addPlayer,
    autoplay: false,
    addHandler,
    removeHandlers,
    onComplete: null,
    mid: '',
    placeholder: '',
    lazy: false,
    modal: false,
    showTitle: false,
    notFoundAudio: '',
    playlist: [''],
    noAds: true,
    endscreen: true,
    recommendations: true,
    moreButton: false,
    nextEpisode: false,
    hasSettings: false,
    pageUrl: window.location.href,
    stylesheet: '',
    trackProgress: 1,
  }

  render(): any {
    return <Player {...this.props} />;
  }
}

export default PlayerContainer;
