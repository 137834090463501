// @flow

import React from 'react';
import LockedAlarm from './LockedAlarm';
import NewAlarm from './NewAlarm';

type Props = {};

type State = {
  alarmValue: string,
  unlockCode: number,
  timerSet: boolean,
  timerRunning: boolean,
  unlockTries: number,
};

const NUMBERS = ['nul', 'een', 'twee', 'drie', 'vier', 'vijf', 'zes', 'zeven', 'acht', 'negen'];
const MAX_WATCHING_TIME = 60; // in minutes
const MIN_WATCHING_TIME = 5; // in minutes
const DEFAULT_START_MINUTES = 15;
const EIGHT_HOURS = 28800000; // in milliseconds
const SIXTY_SECONDS = 60000; // in milliseconds
export const EXPIRE_TIME = EIGHT_HOURS;

export default class WatchAlarm extends React.PureComponent<Props, State> {
  constructor(props: Props): void {
    super(props);
    this.state = {
      alarmValue: this.getSliderValue(),
      unlockCode: this.generateUnlockCode(),
      timerSet: this.hasSessionTimer(),
      timerRunning: !this.isTimerFinished(),
      unlockTries: 0,
    };
  }

  getSliderValue = (): string => (
    this.getStorageMinutes()
  );

  generateUnlockCode = (): number => {
    const min = 1000;
    const max = 9999;
    // The maximum is inclusive and the minimum is inclusive
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  increaseMinutes = (evt: any): void => {
    evt.preventDefault();
    this.setState((prevState) => {
      const sliderVal = parseInt(prevState.alarmValue, 10);
      return { alarmValue: String(Math.min(sliderVal + 5, MAX_WATCHING_TIME)) };
    });
  };

  decreaseMinutes = (evt: any): void => {
    evt.preventDefault();
    this.setState((prevState) => {
      const sliderVal = parseInt(prevState.alarmValue, 10);
      return { alarmValue: String(Math.max(sliderVal - 5, MIN_WATCHING_TIME)) };
    });
  };

  isTimerFinished = (): boolean => Number(this.getStorageMinutes()) <= 0;

  clearAlarm = (): void => {
    if (this.hasSessionTimer()) {
      sessionStorage.removeItem('alarmDuration');
    }
  };

  getStorageMinutes = (): string => {
    const storageTime = sessionStorage.getItem('alarmDuration');
    if (!storageTime) return String(DEFAULT_START_MINUTES);
    const savedTime = Number(storageTime);
    const timeNow = Date.now();
    if (timeNow > savedTime) {
      return '0';
    }
    const timeDiff = new Date(savedTime - timeNow);
    // Round number of minutes to the top so 0 minutes secretly wont have a remainder in seconds
    return String(Math.ceil(timeDiff / SIXTY_SECONDS));
  };

  sliderChange = (evt: any): void => {
    this.setState({ alarmValue: evt.target.value });
  };

  hasTimerExpired = (): boolean => {
    const timer = sessionStorage.getItem('alarmDuration');
    if (timer) {
      // Check if the time difference has surpassed 8 hours.
      return Number(timer) - Date.now() < -EXPIRE_TIME;
    }
    return true;
  }

  hasSessionTimer = (): boolean => !!sessionStorage.getItem('alarmDuration');

  setStorage = (): void => {
    const minutes = parseInt(this.state.alarmValue, 10);
    const toStoreTime = String(Date.now() + (minutes * SIXTY_SECONDS));
    sessionStorage.setItem('alarmDuration', toStoreTime);
  };

  numberToText = (): string =>
    Array.from(String(this.state.unlockCode), Number)
      .map(i => NUMBERS[i])
      .join(' ');

  validateInputDigits = (evt: any) => {
    const element = evt.target;
    const val = element.value;
    // Remove all non digit inputs
    element.value = val.replace(/\D/g, '');
  };

  unlockAlarm = (evt: any): void => {
    const field = document.getElementById('unlockField');
    if (field instanceof HTMLInputElement) {
      if (field.value === String(this.state.unlockCode)) {
        this.clearAlarm();
      } else {
        evt.preventDefault();
        this.setState(prevState => ({ unlockTries: prevState.unlockTries + 1 }));
        field.classList.add('watch-alarm__error');
        setTimeout(() => {
          field.classList.remove('watch-alarm__error');
        }, 2000);
      }
    }
  };

  playLockedAudio = () => {
    const audio = new Audio();
    if (audio instanceof HTMLAudioElement) {
      audio.src = 'assets/fennaWatchAlarmLocked.mp4';
      audio.play();
    }
  };

  formatMinuteText = (text: string): string => (text === '1' ? 'minuut' : 'minuten');

  render(): React$Element<any> {
    if (!this.state.timerSet || this.hasTimerExpired()) {
      return (
        <NewAlarm
          decreaseMinutes={this.decreaseMinutes}
          increaseMinutes={this.increaseMinutes}
          formatMinuteText={this.formatMinuteText}
          setStorage={this.setStorage}
          sliderChange={this.sliderChange}
          alarmValue={this.state.alarmValue}
          maxWatchingTime={MAX_WATCHING_TIME}
          minWatchingTime={MIN_WATCHING_TIME}
        />);
    }
    if (!this.state.timerRunning) {
      this.playLockedAudio();
    }
    return (
      <LockedAlarm
        alarmValue={this.state.alarmValue}
        timerRunning={this.state.timerRunning}
        numberToText={this.numberToText}
        unlockAlarm={this.unlockAlarm}
        formatMinuteText={this.formatMinuteText}
        validateInputDigits={this.validateInputDigits}
      />);
  }
}
