// @flow
/*
  Fixes an item in place but compensates for footer height
*/


import $ from 'jquery';

function scrollFix(elems: Array<any>): void {
  const footerHeight = $('#footer').innerHeight();
  const total = document.documentElement.scrollHeight;
  const windowHeight = window.innerHeight;
  const scrollTop = document.documentElement.scrollTop;
  elems.each((_i, elem) => {
    const fixed = $(elem).attr('data-fixed');
    const maxScrollPos = (total - windowHeight) - footerHeight;
    if (scrollTop >= maxScrollPos) {
      $(elem).removeClass(fixed);
    } else {
      $(elem).addClass(fixed);
    }
  });
}

$(() => {
  const elems = $('[data-behavior=scroll-fix]');
  elems.each((_i, el) => {
    $(el).addClass($(el).attr('data-regular'));
  });
  $(window).scroll(() => { scrollFix(elems); });
  scrollFix(elems);
});
