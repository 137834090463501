/* @flow
 *
 * Individual content item for the subsite home.
 */

import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { setProgBarStyle } from '../../utils/progressBar';

type Card = {
  contentType: string,
  mid: string,
  image: string,
  path: string,
  title: string,
  audio?: string,
  external?: boolean
}

type Props = {
  card: Card,
  index: number,
}

const ChildCard = ({ card, index, ...props }: Props) => {
  const contentTypeImage = `/assets/category-icons/${card.contentType}.svg`;
  const delay = Math.max(index + 1, 0) * 50;
  const audio = new Audio(card.audio);
  const onMouseEnter = () => { audio.play(); };

  // Note: that the progressbar style is updated via setProgBarStyle and not via a behavior,
  // so no behavior is set here.
  return (
    <CSSTransition {...props} classNames="child-card-fade" timeout={500}>
      <div className="l-col-6-s l-col-4-l" style={{ transitionDelay: `${delay}ms` }} onMouseEnter={onMouseEnter}>
        <a href={card.path} className="child-card rounded-lg shadow scaling__wrapper" target={card.external ? '_blank' : ''} rel="noreferrer">
          {card.mid &&
            <div
              className="card__progress-bar"
              data-mid={card.mid}
              style={setProgBarStyle(card.mid)}
            />
          }
          <div className="scaling ratio ratio--16x9">
            <img src={card.image} alt={card.title} />
          </div>
          <div className="child-card__filter">
            <img src={contentTypeImage} alt={card.contentType} />
          </div>
        </a>
      </div>
    </CSSTransition>
  );
};

export default ChildCard;
